import React from 'react'
import {useSelector} from 'react-redux'
import TaskList from '../../components/taskList/TaskList'
import TailWindTable from '../../components/TailwindTable/TailWindTable'
import Calender from '../../components/calender/Calender'
export default function History( { refresh , setRefresh }) {

    let tasks = useSelector(state => state.taskReducer )

  return (
    <section className="history-page bg-gray" >
        <div className="py-5">
            <div className="box-component ">
            <div className="container"><h2 className = "mb-4 fw-bold fs-5">All History</h2></div>
                <TailWindTable refresh={refresh} setRefresh = {setRefresh} />
            </div>
        </div>
        {/* <div className='mt-5 pt-5 ' style={{"width" : "50%"}}>
          <Calender/>
        </div> */}
    </section>
  )
}
