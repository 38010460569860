
import React from "react";
  
export default function Timer(props) {

  function getTimeSpent( time ) {

    if ( props.taskLength == 0 )
    return " 0 seconds "

    let seconds = Math.floor( time / 1000 )

    if ( seconds <= 0 ) return "0 seconds "
    
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    
    return `${(hours > 0 ? hours + " h" : "")}  ${(minutes > 0 ? minutes + " m" : "")} ${ seconds + " s" }`;

    }

  return (
    <div className="timer text-center">
        <p className="mb-0 fs-5">Time Elapsed</p>
      <span className="digits fs-4">
        {getTimeSpent(props.time)}
      </span>
    </div>
  );
}