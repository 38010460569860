import React from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import logo from "../../images/time.svg"
import { useSelector , useDispatch } from 'react-redux'
import {useLocation} from 'react-router-dom'

export default function Header() {

  const dispatch = useDispatch()
  let isLoggedIn = useSelector( state => state.userReducer.isLoggedIn )
  let path = useLocation().pathname

  let handleLogout = () => {
    dispatch({type : "LOGOUT" })
    localStorage.clear("user")
  }

  let handleRefresh = () => {
    window.location.reload()
  }

  return (
    <header className = "header-component">
    <nav className="header-inner d-flex justify-content-between align-items-center container">
        <div className="header-logo" onClick={()=> handleRefresh() }>
            <Link to="/" className="d-flex"><img src={logo} width="40px" alt="" /></Link>
            <Link to="/"><h1>Tap time</h1></Link>
        </div>
        <div className="nav-links">
            
            { 
            isLoggedIn ?  
            <>
              <Link to="/" onClick={()=>handleLogout()}>Logout</Link>
              {path == "/history" ? <Link to="/">Home</Link> : <Link to="/history">History</Link>}
            </>    
            : 
            <>
              <Link to="/login">Login</Link>
              <Link to="/register">Sign up</Link> 
              {path == "/history" ? <Link to="/">Home</Link> : <Link to="/history">History</Link>}
            </> }
            
        </div>
    </nav>
    </header>
  )
}
