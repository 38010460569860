let initialState = []

let initialTasks = localStorage.getItem("tasks") ? JSON.parse( localStorage.getItem("tasks") ) : initialState

let taskReducer = (state = initialTasks , action) => {

    switch(action.type)
    {
        case 'ADD_TASK' : 
        return  [
                 ...state.map( el => {

                    if ( el.endTime == "" ) // end all previous tasks and add new task
                        el.endTime = new Date() 

                    return el
                }) 
                , action.payload 
                ]

        case 'DELETE_TASK' :
        return [

            ...state.filter( el => el.id != action.payload.id )

        ]

        case 'EDIT_TASK' : 
        return [
            ...state.map( el => {

                if ( action.payload.id == el.id )
                return action.payload 
                else return el 

            } )
        ]

        case 'LOGIN_TASKS' : 
        return action.payload

        case 'LOGOUT' :
            return initialState

        default :
            return state 
    }

}

export default taskReducer 