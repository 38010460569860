import React , {useState} from 'react'


export const descContext = React.createContext()
export const descUpdateContext = React.createContext() ; 
export default function DescProvider({ children }) {

    let [ desc , setDesc] = useState("")

  return (
    <descContext.Provider value={desc}>
      <descUpdateContext.Provider value={setDesc}>
        {children}
      </descUpdateContext.Provider>
    </descContext.Provider>
  )
}
