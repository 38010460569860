let initialState = {
    isLoggedIn : false ,
    name : "" , 
    email : "" , 
    password  : ""
}

initialState = localStorage.getItem('user') ? JSON.parse( localStorage.getItem('user') ) : initialState

let userReducer = ( state = initialState , action ) => {

    switch( action.type )
    {
        case 'LOGIN' :
            return {...action.payload , isLoggedIn : true }

        case 'LOGOUT' : 
            return {
                isLoggedIn : false ,
                name : "" , 
                email : "" , 
                password  : ""
            }

        default : 
            return state 
    }

}

export default userReducer