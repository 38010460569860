import React , {useContext, useEffect, useState , useRef } from 'react'
import "./CreateTask.css"
import DateTimePicker from 'react-datetime-picker';
import { useDispatch } from 'react-redux';
import { descContext , descUpdateContext } from '../../defaultDescContext';
import { updateTask } from '../../api/api';
import store from '../../redux/store';
export default function CreateTask({ handleReset , handleStart , time }) {

    let allTasks = store.getState().taskReducer
    let tasks= allTasks.filter( el => {
    
        let currentDate = new Date()
        let date = new Date( el.startTime )
        
        if ( date.getDate() == currentDate.getDate() && date.getMonth() == currentDate.getMonth() )
          return el 
    
    })

    const dispatch = useDispatch() ; 
    let defaultTaskDesc = useContext( descContext )
    const descUpdate = useContext( descUpdateContext )
    let [errorMessage , setErrorMessage] = useState("")
    let [ defaultTime , setDefaultTime ] = useState(true) 
    let [ task , setTask ] = React.useState({
        id : "",
        description : defaultTaskDesc , 
        startTime : new Date() ,
        endTime : "" 
    })


    let handleTime = (time) => {
        let date = new Date()
        let newDate = new Date( date.getFullYear() , date.getMonth() , date.getDate() , time.getHours() , time.getMinutes() , time.getSeconds())
        setDefaultTime( false )
        setTask({ ... task , startTime : newDate })
    }
    
    let handleValidation = () => {
        if ( task.description.length == 0 )
            return false
        
        else return true 
    }


    let handleEnter = async () => {

        if ( allTasks.length == 0 ) 
        {
            startTasks()
            return
        }
        if ( handleValidation() )
        {
            if ( defaultTime )
            {
                descUpdate("")
                dispatch({ 
                    type : "ADD_TASK" , 
                    payload : {
                        ...task , 
                        id : Date.now().toString(36) + Math.random().toString(36).substr(2) ,
                        endTime : new Date() ,
                        startTime :  new Date( new Date().getTime() - time )
                    }
                })
            }
            else
            {
                dispatch({ 
                    type : "ADD_TASK" , 
                    payload : {
                        ...task , 
                        id : Date.now().toString(36) + Math.random().toString(36).substr(2) ,
                        endTime : new Date()
                    }
                })
            }
            updateTask()

            handleReset()
            handleStart()

            setErrorMessage("")
            setTask({
                id : "",
                description : "" , 
                startTime : new Date() ,
                endTime : "" 
            })
            setDefaultTime(true)
        }
        else
        {
            setErrorMessage("Description cannot be empty")
        }
    }

    let startTasks = async () => {
        descUpdate("")
        dispatch({ 
            type : "ADD_TASK" , 
            payload : {
                id : Date.now().toString(36) + Math.random().toString(36).substr(2) ,
                description : "Start" , 
                endTime : new Date() ,
                startTime :  new Date()
            }
        })

        updateTask()
        handleReset()
        handleStart()

        setErrorMessage("")
        setTask({
            id : "",
            description : "" , 
            startTime : new Date() ,
            endTime : "" 
        })
        setDefaultTime(true)
    }

    const taskDescRef = useRef(null)
    useEffect(() => {
        setTask({...task , description : defaultTaskDesc })
        taskDescRef.current.focus()
    },[defaultTaskDesc])

    return (
        <div className="create-task-component-parent">
            <section className='create-task-component box-component px-0'>

                <div className="create-task-inner container">
                    <div className= {`create-task-fields`}>
                        <div>
                            <DateTimePicker disableCalendar={true} disableClock={true}
                            value={task.startTime} format="h:mm a" onChange={(time)=>handleTime(time)} />
                        </div>
                        <div className='task-desc'>
                            <form action="" onSubmit= {(e) => { e.preventDefault() ; handleEnter() }} >
                                <input ref={taskDescRef} type="text" placeholder="Enter task description" value={task.description} onChange={(e)=> { setTask( {...task , description : e.target.value} ) ; descUpdate(e.target.value)} } />
                                <p className='field-error text-danger  mb-0'>{errorMessage}</p>
                            </form>
                        </div>
                    </div>
                </div>

            </section>

        </div>
  )
}
