import store from "../redux/store";
import URL from "../api";

export const updateTask = async () => {

    let state = store.getState()
    let tasks = state.taskReducer

    if ( state.userReducer.isLoggedIn )
    {
        await fetch( 
        `${URL}/user/update` ,
        {
        method : "PUT" ,
        headers : { "Content-type" : 'application/json'} ,
        credentials : 'include' ,
        withCredentials : true ,
        body : JSON.stringify( tasks )
        }
        )
        .then( data => data.json() )
        .then ( response => {
        if ( !response.ok )
        {
        console.error( response )
        throw { code : response.code , message : response.message }
        }
        })

    }

    localStorage.setItem("tasks" , JSON.stringify(state.taskReducer))

}
