import React , {useState , useContext , Fragment , useRef , useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTask } from "../../api/api";
import { descUpdateContext , descContext } from "../../defaultDescContext";
import DateTimePicker from "react-datetime-picker";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import "./tailWindTableItem.css"

export default function TailWindTableItem( { item , index , refresh , setRefresh } ) {

    const dispatch = useDispatch()
    const descUpdate = useContext( descUpdateContext )
    let [taskItem , setTaskItem] = useState( item )
    let [disabled , setDisabled ] = useState( true )
    
    function getTimeSpent( startTime , endTime) {

    startTime = new Date( startTime )
    endTime = new Date( endTime )

    let seconds = Math.floor( ( endTime.getTime() - startTime.getTime() ) / 1000 )

    if ( seconds <= 0 ) return "0 seconds  "
    
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    
    return `${(hours > 0 ? hours + " hours" : "")}  ${(minutes > 0 ? minutes + " minutes" : "")} ${ minutes > 0 ? "" : seconds + " seconds" }`;

    }
    
    let getTime = (date) => {
    date = new Date(date)
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) ;
    
    }

    let handleDelete = async (id) => {
    dispatch({ type : "DELETE_TASK" , payload : { id } })
    setRefresh(state=>!state)
    await updateTask()
    
    }

    let handleChange = ( newVal ) => {
    setTaskItem( {...taskItem , description : newVal })

    }

    let handleEdit = async () => {
        dispatch({ type : "EDIT_TASK" , payload : taskItem })
        updateTask()
        setRefresh(state=>!state)
        setDisabled(true)
    }

    let handleTime = (time , field) => {

        if ( field == "startTime" )
        {
          let start = new Date( taskItem.startTime )
          let newDate = new Date( 
            start.getFullYear() ,
            start.getMonth() ,
            start.getDate() , 
            time.getHours() ,
            time.getMinutes() ,
            time.getSeconds())

            setTaskItem({ ... taskItem , startTime : newDate })
        }
        else if ( field == "endTime" )
        {
          let end = new Date( taskItem.endTime )
          let newDate = new Date( 
            end.getFullYear() ,
            end.getMonth() ,
            end.getDate() , 
            time.getHours() ,
            time.getMinutes() ,
            time.getSeconds())

            setTaskItem({ ... taskItem , endTime : newDate })
        }
    }

    let copyText = ( text ) => {
    descUpdate(text)

    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const taskDescRef = useRef(null)

    useEffect(()=>{
      taskDescRef.current.focus()
    },[])

    return (
        <div className="border-t border-gray-200">
          <div key={taskItem.id} className="table-grid container ">
        
          <div
            className={classNames(
              'tailwind-table-item whitespace-nowrap py-2 pr-3 text-sm font-medium text-gray-900 pl-0 sm:pl-0 lg:pl-0'
            )}>

            { 
              disabled ? <p>{getTime(taskItem.startTime)}</p> 
              :

              <DateTimePicker disableCalendar={true} disableClock={true} clearIcon={null}
                disabled = {disabled}
                onChange={(time)=>handleTime(time , "startTime")}
                value={taskItem.startTime} format="h:mm a" />
              
            }
          </div>
          
          <form action="" onSubmit={(e)=> { e.preventDefault() ; handleEdit() }} className='tailwind-table-item px-3 py-2 text-sm
           text-gray-500 font-light table-cell  break-words break-all'>
            <div>
              { 
                disabled ? <p className="py-2 cursor-pointer" ref={taskDescRef} onClick={()=> copyText(taskItem.description) } >{taskItem.description}</p> 

                :

                <input type="text" value={taskItem.description} 
                className="w-100 py-2 ps-2 bg-transparent table-item-desc-field"
                id="description"
                disabled={disabled}
                ref={taskDescRef}
                onChange={(e)=> handleChange( e.target.value )} />  
                
              }
  
            </div>
          </form>

          <div
            className={classNames(
              'tailwind-table-item  px-3 py-2 font-light text-sm text-gray-500 table-cell'
            )}>
                  {
                  disabled ? 
                  <p>{getTimeSpent( taskItem.startTime , taskItem.endTime)}</p>
                  :
                  <DateTimePicker disableCalendar={true} disableClock={true} clearIcon={null}
                  disabled = {disabled}
                  onChange={(time)=>handleTime(time , "endTime")}
                  value={taskItem.endTime} format="h:mm a" />

                  }
          </div>

          <div
            className={classNames(
              'tailwind-table-item  px-3 py-2 text-sm text-gray-500 hidden sm:table-cell'
            )}
          >
              { disabled ? 
                  <button className="text-indigo-600/75 hover:text-indigo-900/100 me-3"  onClick={()=> { setDisabled(false) ; taskDescRef.current.focus() }}> Edit </button>
                  :  
                  <button className="text-indigo-600 hover:text-indigo-900 me-3" onClick={()=> handleEdit()}> Save </button>
              }
              <button className="text-red-600/75 hover:text-red-600/100" onClick={()=>handleDelete(taskItem.id)} > Delete </button>
          </div>

          <div
            className={classNames(
              'tailwind-table-item pr-3 py-2 text-sm text-gray-500 sm:hidden table-cell'
            )}
          >
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm">
                  <span className="sr-only">Open user menu</span>
                  <svg className="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {
                    disabled ? 
                      <Menu.Item >
                          {({ active }) => (
                            <button
                            onClick={()=> setDisabled(false) }
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block w-100 px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {"Edit"}
                            </button>
                          )}
                      </Menu.Item>

                      :
                      
                      <Menu.Item >
                      {({ active }) => (
                        <button
                        onClick={()=>  handleEdit() }
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block w-100 px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {"Save"}
                        </button>
                      )}
                  </Menu.Item>

                  }

                  <Menu.Item >
                      {({ active }) => (
                        <button
                        onClick={()=>  handleDelete(taskItem.id) }
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block w-100 px-4 my-2 text-sm text-gray-700'
                          )}
                        >
                          {"Delete"}
                        </button>
                      )}
                  </Menu.Item>

                </Menu.Items>
              </Transition>
          </Menu>

          </div>
        
        </div>

        </div>

    )
}