import './App.css';
import React , { useState } from 'react';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import NotFound from './components/notFound/NotFound';
import DescProvider from "./defaultDescContext"
import Register from './pages/register/Register';
import Login from './pages/login/Login';
import Auth from './components/auth/Auth';
import History from './pages/history/History';
import URL from './api';
import {useSelector , useDispatch} from 'react-redux'

function App() {

  const dispatch = useDispatch()
  const [ refresh , setRefresh ] = useState(false) // will be passed to child , so it can re render parent

  let isLoggedIn = useSelector(state=>state.userReducer.isLoggedIn)
  
  let getTasks = async () => {

    if ( !isLoggedIn ) return 

    try{
      await fetch(`${URL}/user/getTasks` , { credentials : "include" }).then(data=>data.json())
      .then( resp => {
        if ( !resp.ok )
        {
          console.log(resp)
          return
        }

        dispatch({type:"LOGIN_TASKS", payload : resp.tasks})
        localStorage.setItem("tasks" , JSON.stringify(resp.tasks))
        setRefresh( state => !state )

      })
    }
    catch(err)
    {
      console.error(err)
      throw err
    }

  }

  React.useEffect(()=>{
    getTasks()
  },[])


  return (
    <div className="App">

    <Router>
      <DescProvider>
        <Header/>

        <Routes>
          <Route exact path = "/" element={<Home refresh={refresh} setRefresh = {setRefresh} />} />

          <Route exact path = "/history" element={<History refresh={refresh} setRefresh = {setRefresh} />} />

          <Route exact path = "/register" element= { <Auth Component={Register} />}  />

          <Route exact path = "/login"  element= { <Auth Component={Login} />} />

          <Route path = "*" element={<NotFound/>} />

        </Routes>

      </DescProvider>
    </Router>

    </div>
  );
}

export default App;
