import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import store from "../../redux/store"
import { useNavigate } from 'react-router-dom'
import URL from "../../api"
import './Register.css'
import { useAlert } from 'react-alert'
import { ThreeCircles } from 'react-loader-spinner'


export default function Register() {

    let alert = useAlert()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let [ loading , setLoading ] = useState( false )
    let [ user , setUser ] = useState(
        {
            name : "" ,
            email : "" , 
            password : ""  ,
            tasks : store.getState().taskReducer
        }
    ) 

    let [ error , setError ] = useState({
        name : "" , 
        email : "" , 
        password : "" 
    })


    let handleValidation = () => {

        if ( user.name.length < 2 )
        {
            setError( {...error , name : "Name must contain atleast 2 characters"})
            return
        }
        
        if ( user.password.length < 6 )
        {
            setError( { ...error , password : "Password must contain atleast 6 characters"})
            return 
        }

    }

    let handleSubmit = async () => {

        handleValidation()

        try{
            setLoading(true)
            await fetch(
                `${URL}/user/register`, 
                { 
                    method : "POST" ,
                    headers : { "Content-type" : "application/json"} ,
                    credentials : "include" , 
                    withCredentials : true ,
                    body : JSON.stringify(user)
                }
                ).then( data => data.json() )
                .then( response => {
                    
                    setLoading(false)
                    if ( !response.ok )
                    throw { code : response.code , message : response.message }
                    else
                    {
                        alert.success("Account Created ! ")
                        dispatch({ type : 'LOGIN' , payload : {...response.user , isLoggedIn : true }})
                        localStorage.setItem("user" , JSON.stringify({...response.user , isLoggedIn : true }))
                        setUser( {
                            name : "" ,
                            email : "" , 
                            password : ""  ,
                            tasks : []
                        } )
                        setError({
                            name : "" , 
                            email : "" , 
                            password : "" 
                        })
                        navigate("/")
                    }
    
                })

        }
        catch(err)
        {
            setLoading(false)
            if ( err.code == 11000)
            setError({...error , email : "email already in use"})
            else 
            console.log(err)
        }



    }


  return (
    <section className="register-component">

        <div className="container pt-5">
            <div className="register-inner box-component p-4">
                <h2 className='fs-3 fw-500'>Register Account</h2>

                <div className="register-form">
                    <form action="" onSubmit={(e)=> { e.preventDefault() ; handleSubmit()} }>

                        <div className="row mt-2">
                            <div className="col-md-4">
                                <input type="text" placeholder='Enter Name' className='w-100 mt-2' value={user.name} onChange={({target})=>setUser({...user , name : target.value})} />
                                <span className='text-danger'>{error.name}</span>
                            </div>
                            <div className="col-md-4">
                                <input type="email" placeholder='Enter Email' className='w-100 mt-2'  value={user.email} onChange={({target})=>setUser({...user , email : target.value})}/>
                                <span className='text-danger'>{error.email}</span>
                            </div>
                            <div className="col-md-4" >
                                <input type="password" placeholder='Enter password' className='w-100 mt-2' value={user.password} onChange={({target})=>setUser({...user , password : target.value})} />
                            </div>
                        </div>

                        <button className="btn btn-primary mt-4 px-5 py-2" disabled={loading} >Register</button>

                    </form>
                </div>

                {loading ? 
                    <div className='loading-wrapper'>
                        <ThreeCircles
                        height="100"
                        width="100"
                        color="#0d6efd"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor=""
                        innerCircleColor=""
                        middleCircleColor=""/>
                    </div>
                    :
                    <></>
                }

            </div>
        </div>

    </section>
  )
}
