import React , {useState , useContext} from "react"
import store from '../../redux/store'
import { useDispatch } from 'react-redux'
import { descContext, descUpdateContext } from '../../defaultDescContext'
import { updateTask } from '../../api/api'
import TailWindTableItem from "./TailWindTableItem"
import TailWindTableItemEdited from "./tailWindTableItemEdited"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TableWrapper( props ) {

  let allTasks = store.getState().taskReducer
  let tasks = [...allTasks]

  // if it is home page then filter out and show only current day tasks otherwise show all
  if ( props.page == "home" )
  tasks = tasks.filter( el => {
    
    let currentDate = new Date()
    let date = new Date( el.endTime )
    
    if ( date.getDate() == currentDate.getDate() && date.getMonth() == currentDate.getMonth() )
      return el 

  })

  const descUpdate = useContext( descUpdateContext )
  const dispatch = useDispatch()

  let startTasks = async () => {
    dispatch({ 
        type : "ADD_TASK" , 
        payload : {
            id : Date.now().toString(36) + Math.random().toString(36).substr(2) ,
            description : "Start" , 
            endTime : new Date() ,
            startTime :  new Date()
        }
    })
    
    updateTask()
    props.handleStart()
    props.setRefresh(state=>!state)
    }

  return (
    <div className="px-0 sm:px-6 lg:px-8 tail-wind-table-wrapper">
      <div className="mt-8 flex flex-col">
        <div>
          <div className="inline-block min-w-full  align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              {/* <table className="min-w-full border-separate " style={{ borderSpacing: 0 }}>
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0   bg-gray-50 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell pl-4 sm:pl-6 lg:pl-8"
                    >
                      Start Time
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0   bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell"
                    >
                      Task Description
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0   bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell "
                    >
                      Time spent
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0  bg-gray-50 bg-opacity-75 pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell"
                    >
                      
                    </th>
                    
          
                  </tr>
                </thead>
                <tbody className="bg-white table-body">
                  {tasks.map((el,ind) => (
                    <TailWindTableItem item={el} key={el.id} index ={ind} setRefresh = {props.setRefresh} refresh = { props.refresh } />
                  ))}
                </tbody>
              </table> */}
              <main className="table-wrapper min-w-full border-separate">
                <div className="table-heading bg-gray-50">
                  <div className="container table-grid">
                    <div className="heading-item sticky top-0   bg-gray-50 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell pl-0 sm:pl-0 lg:pl-0">
                      <h4>Start Time</h4>
                    </div>
                    <div className="heading-item sticky top-0   bg-gray-50 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell px-3">
                      <h4>Task Description</h4>
                    </div>
                    <div className="heading-item sticky top-0   bg-gray-50 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell px-3">
                      <h4>Time spent </h4>
                    </div>
                    <div className="heading-item sticky top-0   bg-gray-50 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter table-cell px-3">
                      <h4></h4>
                    </div>
                  </div>
                  <div className="table-body bg-white ">
                    {tasks.map((el,ind) => (
                      <TailWindTableItemEdited item={el} key={el.id} index ={ind} setRefresh = {props.setRefresh} refresh = { props.refresh } />
                      
                    ))}
                  </div>
                </div>
              </main>
            </div>
          </div>
          {
            allTasks.length == 0 ? 
              <button className="btn btn-primary p-3 mx-auto d-block my-5" onClick={()=>startTasks()}>Start Tasks</button>
              :
              <></>
          }
        </div>
      </div>
    </div>
  )
}
