import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
export default function Auth({ Component }) {

    const navigate = useNavigate();
    let isLoggedIn = useSelector( state=> state.userReducer.isLoggedIn )

    React.useEffect(()=>{
        if ( isLoggedIn )
            navigate("/")
    },[])

  return (
        <div>
            <Component/>
        </div>
  )
}
