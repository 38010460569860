import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import URL from "../../api"
import { useAlert } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import { ThreeCircles } from 'react-loader-spinner'
import '../register/Register.css'


export default function Login() {

    let alert = useAlert()
    let [ loading , setLoading ] = useState(false) 
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let [ user , setUser ] = useState(
        {
            email : "" , 
            password : ""  ,
        }
    ) 

    let [ error , setError ] = useState({
        password : "" 
    })


    let handleValidation = () => {

        if ( user.password.length < 6 )
        {
            setError( {...error , name : "Password must contain atleast 6 characters"})
            return
        }

    }

    let handleSubmit = async () => {

        handleValidation()

        try{
            setLoading(true)
            await fetch(
                `${URL}/user/login`, 
                { 
                    method : "POST" ,
                    headers : { "Content-type" : "application/json"} ,
                    credentials : "include" , 
                    withCredentials : true ,
                    body : JSON.stringify(user)
                }
                ).then( data => data.json() )
                .then( response => {
                    setLoading(false)
                    if ( !response.ok )
                    {
                        alert.error(response.message)
                        throw { code : response.code , message : response.message }
                    }
                    else
                    {
                        alert.success("Logged In !")
                        let userInfo = {...response.user , isLoggedIn : true }
                        delete userInfo.tasks 
                        dispatch({ type : 'LOGIN' , payload : userInfo })
                        localStorage.setItem("user" , JSON.stringify(userInfo))
                        dispatch({type : "LOGIN_TASKS" , payload : response.user.tasks })
                        localStorage.setItem("tasks" , JSON.stringify(response.user.tasks))
                        setUser( {
                            email : "" , 
                            password : "" 
                        } )
                        setError({
                            password : "" 
                        })
                        navigate("/")
                    }
    
                })

        }
        catch(err)
        {
            console.log(err)
            setLoading(false)
        }



    }


  return (
    <section className="register-component">

        <div className="container pt-5">
            <div className="register-inner box-component p-4">
                <h2 className='mb-0 fs-3 fw-500'>Login</h2>

                <div className="register-form">
                    <form action="" onSubmit={(e)=> { e.preventDefault() ; handleSubmit()} }>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <input type="email" placeholder='Enter Email' className='w-100 mt-2'  value={user.email} onChange={({target})=>setUser({...user , email : target.value})}/>
                            </div>
                            <div className="col-md-6" >
                                <input type="password" placeholder='Enter password' className='w-100 mt-2' value={user.password} onChange={({target})=>setUser({...user , password : target.value})} />
                            </div>
                        </div>

                        <button className="btn btn-primary mt-4 px-5 py-2" disabled={loading}>Login</button>

                    </form>
                </div>

                {loading ? 
                <div className='loading-wrapper'>
                    <ThreeCircles
                    height="100"
                    width="100"
                    color="#0d6efd"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor=""
                    innerCircleColor=""
                    middleCircleColor=""/>
                </div>
                :
                <></>
            }

            </div>
        </div>

    </section>
  )
}
