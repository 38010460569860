import React , {useState} from 'react'
import CreateTask from '../../components/createTask/CreateTask'
import "./Home.css"
import {useSelector} from 'react-redux'
import store from '../../redux/store'
import Timer from '../../components/timer/Timer'
import TailWindTable from '../../components/TailwindTable/TailWindTable'

export default function Home( { refresh , setRefresh } ) {

  let getTodayTasks = ( arr ) => {
    return arr.filter( el => {
    
      let currentDate = new Date()
      let date = new Date( el.endTime )
      
      if ( date.getDate() == currentDate.getDate() && date.getMonth() == currentDate.getMonth() )
        return el 
  
    })
  }

  let allTasks = useSelector(state => state.taskReducer)
  let tasks = getTodayTasks( allTasks )

  let setTimer = ( tasks ) => {
    let lastTask = tasks[ tasks.length - 1 ]
    if ( !lastTask ) return 0 
    
    lastTask.endTime = new Date( lastTask.endTime )
    let currentTime = new Date().getTime()
    let prevCurrentTimeDifference = Math.abs( currentTime - lastTask.endTime.getTime() ) 
    return prevCurrentTimeDifference
  }

  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(setTimer(allTasks))

  let getDate = (date) => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour12 : true
    };
    return date.toLocaleString('en-US', options) ;

  }

  let getHourMinute = () => {
    let date = new Date()
    if ( date.getHours() < 10 || ( date.getHours() > 12 && date.getHours() < 22 ) )
    return date.toLocaleTimeString( 'en-US', { hour: '2-digit', minute: '2-digit' }).substring(1)
    else
    return date.toLocaleTimeString( 'en-US', { hour: '2-digit', minute: '2-digit' })
  }

  React.useEffect(() => {
    setTime( setTimer(allTasks) ) ; 
  } , [refresh] )

  React.useEffect(() => {
    let interval = null;
    if (isActive && isPaused === false ) 
    {
      interval = setInterval(
        () => {

          //let currentTasks = getTodayTasks( store.getState().taskReducer )
          let currentTasks = store.getState().taskReducer
          setTime( setTimer(currentTasks) + 1000);
        }
      , 1000 )

    } 
    else if ( time > 0 )
    {
      handleStart()
      interval = setInterval(
        () => 
        {
          //let currentTasks = getTodayTasks( store.getState().taskReducer )
          let currentTasks = store.getState().taskReducer
          setTime( setTimer(currentTasks) + 1000);
        }
        , 1000 )
    }
    else
    {
    clearInterval(interval);
    }
    return () => {
    clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };
  
  const handleReset = () => {
    setIsActive(false);
    setTime(0);
  };



  return (
    <section className='home-component '>
          <div className="showcase-time w-100 py-2 box-component">
            <div className='container d-flex justify-content-between align-items-center'>
              <div className="current-time fs-5">
                { getDate( new Date()) }
                <br />
                { getHourMinute()}
              </div>
              <Timer time={time} taskLength = { allTasks.length } />
            </div>

          </div>
        <div>
            <div className="task-list-section section-padding">
              <div className="box-component">
                <TailWindTable setRefresh={setRefresh} refresh={refresh} handleStart = {handleStart} page = {"home"} />
              </div>
            </div>
            <div className = "create-task-section pb-5">
                <CreateTask handleStart = {handleStart} handleReset = {handleReset} time = {time} />
            </div>
        </div>

    </section>
  )
}
